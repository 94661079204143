import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import {shuffleArray} from 'helpers/array-helper';
import {playerUiTexts} from 'data/ui-texts/player-ui-texts';
import {checkIfConditionsAreFulfilled} from 'helpers/effect-helper';
import TaskIntro from 'components/game/module/engines/task-intro/task-intro';
import Audio from 'components/ui/audio/audio';
import Button from 'components/ui/button/button';
import './survey.scss';

const Survey = (props) => {
	const {
		languageId,
		playerTaskData, 
		taskData, 
		moduleData,
		updateLoggedTime,
		handleCompleteTask
	} = props;

	/* Check if completed already */
	const isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);

	/* Task id */
	const [taskId, setTaskId] = useState(null);

	/* Animate selected options */
	const [animateSelectedOptions, setAnimateSelectedOptions] = useState(false); 
	
	/* Get min/max number of options to select */
	const minOptionsToSelect = taskData.minOptionsToSelect;
	const maxOptionsToSelect = taskData.maxOptionsToSelect;

	/* Select x options text */
	let selectOptionsText = null;
	if (taskData.showRequiredNumberOfAnswers) {
		if (minOptionsToSelect === maxOptionsToSelect) {
			selectOptionsText = JSON.parse(JSON.stringify(getText(playerUiTexts.surveyChooseXOptions, languageId)))
				.replace('%X%', maxOptionsToSelect) + ':';
		} else {
			selectOptionsText = JSON.parse(JSON.stringify(getText(playerUiTexts.surveyChooseMaxXOptions, languageId)))
				.replace('%X%', maxOptionsToSelect) + ':';
		}
	}

	/* Track available and selected options */
	const [optionIds, setOptionIds] = useState([]);
	const [selectedOptionIds, setSelectedOptionIds] = useState([]);

	/* Show "done btn" if min options are selected */
	const showDoneBtn = (!isCompleted && taskData.id === taskId && selectedOptionIds.length >= minOptionsToSelect);

	/**
	 * Get option ids, shuffle them
	 * @returns {array} optionIds
	 */
	const getOptionIds = () => {
		if (!taskData.options) return [];
		if (taskData.hasOwnProperty('shuffleOptions') && !taskData.shuffleOptions) {
			return taskData.options.map((option) => {return option.id;});
		}
		return shuffleArray(taskData.options.map((option) => {return option.id;}));
	};

	/* Update selected items if new task */
	useEffect(() => {
		setOptionIds(getOptionIds());
		setSelectedOptionIds([]);
		setAnimateSelectedOptions(false);
		setTaskId(taskData.id);
		const optionsElement = document.getElementById('surveyOptions');
		if (optionsElement) optionsElement.scrollTop = 0;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);


	/**
	 * Select option
	 * @param {number} optionId 
	 * @returns 
	 */
	const selectOptionId = (optionId) => {
		/* Already completed */
		if (isCompleted === true) return;

		/* Update logged time */
		updateLoggedTime();

		const optionIndex = selectedOptionIds.indexOf(optionId);
		const newSelectedOptionIds = JSON.parse(JSON.stringify(selectedOptionIds));
		if (optionIndex >= 0) {
			/* De-select option */
			newSelectedOptionIds.splice(optionIndex, 1);
		} else {
			/* Max number of options already selected */
			if (selectedOptionIds.length >= maxOptionsToSelect) {
				if (maxOptionsToSelect === 1) {
					/* Just switch which one is selected */
					newSelectedOptionIds.splice(optionIndex, 1);
					newSelectedOptionIds.push(optionId);
					setAnimateSelectedOptions(true);
				} else {
					return;
				}
			} else {
				/* Select option */
				newSelectedOptionIds.push(optionId);
				setAnimateSelectedOptions(true);
			}
		}
		setSelectedOptionIds(newSelectedOptionIds);
	};

	/**
	 * Complete task
	 */
	const completeTask = () => {
		/* Get effects */
		const effects = [];
		if (taskData.doneEffects && taskData.doneEffects.length > 0) {
			taskData.doneEffects.forEach((effect) => {
				const conditionsAreFulfilled = checkIfConditionsAreFulfilled(effect, 0);
				if (conditionsAreFulfilled) {
					effects.push(effect);
				}
			});
		}

		/* Save completed task */
		handleCompleteTask(
			'survey', 
			0,
			0, 
			effects,
			{selectedOptionIds: selectedOptionIds}
		);
	};

	return (
		<div className={'Survey ' + languageId
			+ (taskData.subtype ? ' ' + taskData.subtype : '') 
			+ (taskData.layout ? ' ' + taskData.layout : '')
		}
		>
			{taskData.id === taskId && <div className="Survey-content">
				<div id="taskIntro" className="Survey-intro">
					<TaskIntro 
						languageId={languageId}
						moduleId={moduleData.id}
						text={taskData.text ? getText(taskData.text, languageId) : null}
						selectOptionsText={selectOptionsText}
						image={taskData.image}
						fileName={languageId + '-' + taskData.taskId}
					/>
				</div>
				<div id="surveyOptions" className="Survey-options">
					<div className={'Survey-optionsWrap'}>
						{optionIds.map((optionId, index) => {
							const optionData = taskData.options.find((option) => {return option.id === optionId;});
							if (!optionData) return null;
							const isSelected = selectedOptionIds.indexOf(optionData.id) >= 0;
							let optionClass = 'Survey-option';
							if (isSelected) optionClass += ' selected';
							if (isSelected && animateSelectedOptions) optionClass += ' animate';
							if (!isSelected && isCompleted) optionClass += ' completed';
							if (
								!isSelected && 
								!isCompleted && 
								selectedOptionIds.length >= maxOptionsToSelect &&
								maxOptionsToSelect > 1
							) {
								optionClass += ' disabled';
							}	
							if (taskData.layout) optionClass += ' option-' + optionData.id + ' position-' + (index + 1);

							return (
								<div 
									key={optionData.id} 
									className={optionClass} 
									onClick={() => {selectOptionId(optionData.id);}}
								>
									<span></span> {/* Used for image */}
									<span>{optionData.text ? getText(optionData.text, languageId) : null}</span>
									{(optionData.text && taskData.subtype !== 'images') && 
										<div className="Survey-audio">
											<Audio 
												type='task-option'
												languageId={languageId}
												moduleId={moduleData.id}
												fileName={languageId + '-' + taskData.taskId + '-' + optionData.id}
											/>
										</div>
									}
								</div>
							);
						})}
					</div>
				</div>
			</div>}

			{/* Done button */}
			{showDoneBtn && <div className="Survey-doneBtn">
				<Button
					classes={['confirmTask']}
					text={getText(playerUiTexts.ok, languageId)}
					onClick={completeTask}
				/>
			</div>}
		</div>
	);
};

Survey.propTypes = {
	languageId: PropTypes.string.isRequired,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	moduleData: PropTypes.object.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired
};

export default Survey;
