const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-welcome',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Velkommen til gentræning 1B. Nu skal vi ind i selve produktionen. Husk du skal have tre stjerner for at gennemføre.`,
			en: `Welcome to hygiene retraining B. Now we are entering the production area. Remember, you must get three stars to complete the module.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-production-many-procedures',
		text: {
			da: `Så er vi inde i produktionen. Her er der mange procedurer som skal overholdes. Og meget af det handler om at undgå forurening.`,
			en: `Now we're inside the production area. Here there are lots of procedures to follow. And that is to a large extent in order to avoiod contamination.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-always-do-before-work',
		text: {
			da: `Hvad skal du altid gøre lige inden du går i gang med at arbejde?`,
			en: `What must you always do before you start working?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Tjekke at mit arbejdstøj og udstyr ikke er i stykker eller snavset`,
					en: `Check that my work clothes and equipment isn't broken and/or dirty`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Give min leder en high five`, 
					en: `High five my manager`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Synge Kelsen-sangen`,
					en: `Sing the Kelsen-song`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Vaske hænder`,
					en: `Wash my hands`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det gjorde du på vej ind i produktionen.`,
								en: `You did that already as you entered the production area.`
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m4-spot-errors-person2',
		layout: 's1-m4-person2',
		background: 'production',
		text: {
			da: `Se om du kan spotte de 4 fejl på billedet her. Tryk på dem og tryk så OK, når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: `See if you can spot the 4 problems here. Click OK when you are ready or if you can't find them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'bandaid',}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'glove'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'blouse'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'knife',
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt fanget. Sådan en kniv er ikke godkendt værktøj.`,
								en: `Nice catch! A knife like that is not an approved tool.`
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-contamination-3-types',
		text: {
			da: `Der er tre forskellige måder vores produkter kan blive forurenet på: fysisk, kemisk og mikrobiologisk.`,
			en: `There are three different ways in which product can get contaminated: physically, chemically, and microbiologically.`
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-contamination-reactions',
		text: {
			da: `Hvordan du skal reagere på forurening afhænger af, hvilken type det er. Lad os kigge nærmere på det.`,
			en: `What to do about it depends on the type of contamination. Let's take a closer look at it.`
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m4-order-metal-detector',
		text: {
			da: `Der er udslag på metaldetektoren. Der er risiko for, der er metal i produkterne. Hvad skal du gøre? Sæt nedenstående handlinger i den rigtige rækkefølge.`,
			en: `You get a signal from the metal detector. There is a risk of foreign bodies in the products. What should you do? Place the actions below in the correct order.`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Undersøg de kager der er skubbet ud`,
					en: `Examine the cookies that have been pushed out`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Ved fund af metal tilkald leder`,
					en: `If metal is found, summon a manager`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Indlever fund med information om kode på bigbag, m.m. `,
					en: `Turn in findings with information about big bag code etc.`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Fortsæt arbejdet, men vær opmærksom`,
					en: `Continue working, but stay alert`
				},
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-glove-tip-missing',
		text: {
			da: `Du opdager at fingerspidsen mangler på en af dine plasthandsker. Hvad skal du gøre?`,
			en: `You notice that the finger tip of one of your plastic gloves is missing. What should you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Informere min leder med det samme`,
					en: `Immediately report it to my manager`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Fortsætte arbejdet. Jeg kan skifte handske i næste pause`, 
					en: `Continue working - I can change glove in my next break`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: {
					da: `Skifte handske, så jeg kan arbejde videre`,
					en: `Change glove so I can continue work`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Jeg behøver slet ikke skifte handske`,
					en: `I don't need to change my glove`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m4-spot-errors-production-hygiene-issues',
		layout: 's1-m2-production-hygiene-issues',
		background: 'production',
		text: {
			da: `Tryk på de 4 fejl. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: `Click on the 4 issues. Click OK when you are ready or if you can't find them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'box-on-floor'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'yellow-tape'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'spray-can'}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {id: 'big-bag'}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-trash-big-bags',
		text: {
			da: `Hvis der kommer affald i vores big bags, så kan det være et problem. Hvorfor?`,
			en: `If we get trash in our big bags, it can be a problem. Why?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Det kan forurene vores produkter`,
					en: `It can contaminate our products`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Det ser ikke pænt ud`, 
					en: `It doesn't look nice`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: {
					da: `Det kan give fremmedlegemer i vores produkter`,
					en: `It can cause foreign bodies to enter our products`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Så vælter de nemmere`,
					en: `They'll fall over more easily`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-jug-of-cleaning-agent',
		text: {
			da: `Du ser en dunk med rengøringsmiddel stå på båndet. Hvad gør du?`,
			en: `You spot a bottle of cleaner on the conveyer belt. What do you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Lader det stå`,
					en: `Leave it`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					da: `Fjerner det og tilkalder så en leder`, 
					en: `Remove it and summon a manager`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt! Vi skal sikre os at vores produkter ikke bliver forurenet.`,
								en: `Great! We have to make sure, that our products don't get contaminated.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Tilkalder en leder`,
					en: `Summon a manager`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Altid godt at spørge, men husk også at fjerne flasken.`,
								en: `It's never wrong to ask. But remember to also remove the bottle.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Fjerner det`,
					en: `Remove the bottle`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-ask-if-in-doubt',
		text: {
			da: `Hvis du er i tvivl om hvorvidt noget er blevet kemisk forurenet, så spørg din leder.`,
			en: `If aren't sure whether or not something has been contaminated chemically, ask your manager.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-allergies',
		subtype: 'images',
		layout: 's1-m4-allergies',
		text: {
			da: `Forurening kan være et problem for folk med allergi. Klik på tre allergener.`,
			en: `Contamination can also cause problems for people with allergies. Click on three allergens.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // hazel nuts
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // egg
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // strawberries
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true, // milk
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Smør indeholder også mælk.`,
								en: `Butter also contains milk`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // pineapple
			}),
		]
	}),	
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-why-contamination-problem',
		background: 'production',
		backgroundModifier: 'machine-2',
		text: {
			da: `Vores produkter kan blive forurenet på forskellige måder. Hvorfor er forurening et problem?`,
			en: `Our products can become contaminated in different ways. Why is contamination a problem?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true,
				text: {
					da: `Det kan være farligt`,
					en: `It can be dangerous`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true,
				text: {
					da: `Det kan ødelægge smagen`, 
					en: `It can ruin the taste`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, isCorrect: true,
				text: {
					da: `Det kan give utilfredse kunder`,
					en: `It can cause unhappy customers`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, 
				text: {
					da: `Det udleder mere CO2`,
					en: `It emits more CO2`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, 
				text: {
					da: `Forurening er kun et problem, hvis Kvalitet ser det`,
					en: `Contamination is only an issue if Quality sees it`
				},

			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, isCorrect: true,
				text: {
					da: `Det er ulovligt`,
					en: `It's illegal`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-first-to-spot-problems',
		background: 'production',
		backgroundModifier: 'machine-2',
		text: {
			da: `Hygiejne er ekstremt vigtigt i produktionen. Og fordi du er tæt på produktet, er du den første der kan lægge mærke til problemer.`,
			en: `Hygiene is extremely important in production. And because you work near the products, you'll be the first to spot problems.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-spot-problem-first-action',
		background: 'production',
		backgroundModifier: 'machine-2',
		text: {
			da: `Hvad er det første du skal gøre hvis du spotter noget, som måske er et problem?`,
			en: `What's the first thing you should do, if you spot something that might be a problem?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					da: `Stands produktionen`,
					en: `Stop production`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, 
				text: {
					da: `Skriv en note`, 
					en: `Write a note`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `Smid alt produkt i nærheden af problemet ud`,
					en: `Discard all product near the problem`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true,
				text: {
					da: `Spørg en leder`,
					en: `Ask a manager`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Lige præcis! Hvis du er i tvivl, så skal du spørge en leder.`,
								en: `Exactly! If you are in doubt, always ask a manager.`
							}
						})
					})
				]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-almost-done',
		text: {
			da: `Du er næsten igennem modulet.`,
			en: `You are almost done with the module.`
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-hygiene-extra-attention',
		shuffleOptions: false,
		showRequiredNumberOfAnswers: false,
		text: {
			da: `Hvorfor skal vi være ekstra opmærksomme på hygiejne i produktionen?
				<br /><br />Vælg 1 - husk at læse alle svarmulighederne.`,
			en: `Why must we be especially aware of hygiene in production?
				<br /><br />Choose 1 - and remember to read all the options:`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `På grund af lovkrav`,
					en: `It's the law`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Fordi dårlig hygiejne kan gøre folk syge`, 
					en: `Because bad hygiene can make people sick`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, 
				text: {
					da: `For at sikre høj kvalitet`,
					en: `To ensure high quality`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true,
				text: {
					da: `Af alle ovenstående grunde`,
					en: `For all of the reasons above`
				},
			}),
		]
	}),	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-survey',
		text: {
			da: `Til allersidst er der fire spørgsmål om fødevarekulturen på Kelsen. Her er der ikke rigtige og forkerte svar. Spørgsmålene giver ikke point, og besvarelserne er anonyme.`,
			en: `Finally, we have four questions about food safety culture at Kelsen. There are no right and wrong answers here. The questions don't earn you points, and you answer them anonymously.`
		}
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-authority-responsibility',
		shuffleOptions: false,
		title: {
			da: `Myndighed og ansvar`,
			en: `Authority and responsibility`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Jeg har myndighed og ansvar for at reagere, hvis jeg ser, at der er risiko for fødevaresikkerheden?`,
			en: `How strongly do you agree with the following statement:
				<br />I have authority and responsibility to react if I spot something that can be a hazard to food safety?`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-react-if-others-dont-follow-rules',
		shuffleOptions: false,
		title: {
			da: `Reagerer hvis andre ikke følger reglerne`,
			en: `Reacts if there is an issue`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Jeg reagerer hvis jeg ser andre, der ikke følger hygiejnereglerne?`,
			en: `How strongly do you agree with the following statement:
				<br />I react if I see someone who isn't following hygiene guidelines.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-safety-issue-solve-or-inform',
		shuffleOptions: false,
		title: {
			da: `Løser/melder sikkerhedsproblemer`,
			en: `Solves/reports safety issues`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Hvis jeg ser et fødevaresikkerhedsproblem, så løser jeg det eller informerer min nærmeste leder?`,
			en: `How strongly do you agree with the following statement:
				<br />If I see a food safety issue, I'll either solve it or inform my closest manager.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-reports-leads-to-action',
		shuffleOptions: false,
		title: {
			da: `Der gøres noget ved rapporteringer`,
			en: `Reports are handled`
		},
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Hvis jeg rapporterer en risiko for fødevaresikkerheden, så bliver der gjort noget ved det?`,
			en: `How strongly do you agree with the following statement:
				<br />If I report a food safety risk, something will be done to handle it.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				}
			}),
		]
	}),	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-finish',
		text: {
			da: `Godt gået! 
				<br />Du er nu igennem gentræningen. Hvis du er i tvivl om noget, så spørg din holdleder. 
				<br />God arbejdslyst derude!`,
			en: `Nice work! 
				<br />If you have three stars, you have completed the retraining. If not, you have to retake the module. If you are in doubt about anything, ask a manager.`
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m4-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};