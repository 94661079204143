// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const generalUiTexts = {
	hygieneTraining: {
		da: 'Hygiejnetræning',
		en: 'Hygiene training'
	},
	start: {
		da: 'Start',
		en: 'Start'
	},
	loading: {
		da: 'Loader',
		en: 'Loading',
		pl: 'Ładowanie',
		de: 'Wird geladen'
	}
};


module.exports = {
	generalUiTexts
};