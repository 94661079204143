import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import appConfig from 'config/app.config';
import {getText} from 'helpers/language-helper';
import {adminUiTexts} from 'data/ui-texts/admin-ui-texts';
import {scenariosData} from 'data/scenarios-data';
import Button from 'components/ui/button/button';
import './admin-games.scss';

const AdminGames = (props) => {
	const {
		languageId,
		sortProperty,
		sortDirection,
		gamesData,
		playersData,
		sortGamesByProperty,
		handleSelectGame,
		handleToggleGameStats
	} = props;

	return (
		<div className="AdminGames">
			<div className="AdminGames-header">
				<div 
					className={'AdminGames-cell name' 
						+ (sortProperty === 'name' ? ' sortedBy ' + sortDirection : '')} 
					onClick={() => {sortGamesByProperty('name');}}
				>
					<span>{getText(adminUiTexts.name, languageId)}</span>
				</div>
				<div 
					className={'AdminGames-cell created' 
							+ (sortProperty === 'created' ? ' sortedBy ' + sortDirection : '')} 
					onClick={() => {sortGamesByProperty('created');}}
				>
					<span>{getText(adminUiTexts.created, languageId)}</span>
				</div>
				<div className="AdminGames-cell scenario">
					<span>{getText(adminUiTexts.scenario, languageId)}</span>
				</div>
				<div className="AdminGames-cell facilitators">
					<span>{getText(adminUiTexts.facilitators, languageId)}</span>
				</div>
				<div className="AdminGames-cell activePlayers">
					<span>{getText(adminUiTexts.players, languageId)}</span>
				</div>
				<div className="AdminGames-cell gameUrl">
					<span>{getText(adminUiTexts.gameUrl, languageId)}</span>
				</div>
				<div className="AdminGames-cell gameInfo" />
				<div className="AdminGames-cell gameStats" />
				
			</div>
			<div className="AdminGames-body">
				{gamesData.map((game, index) => {
					const numberOfFacilitators = (game.facilitatorEmails ? game.facilitatorEmails.length : 0);
					const numberOfPlayers = playersData.filter((player) => {
						return (player.gameId === game.id && player.isInactive !== true);
					}).length;
					const created = (game && game.created ? dayjs(game.created).format('DD/MM-YY') : '-');
					const scenarioId = (game && game.scenarioId ? game.scenarioId : 'scenario-1');
					const scenarioData = scenariosData.find((sc) => {return sc.id === scenarioId;});
					return (
						<div key={index} className="AdminGames-row">
							<div className="AdminGames-cell name"><span>{game.name}</span></div>
							<div className="AdminGames-cell created"><span>{created}</span></div>
							<div className="AdminGames-cell scenario">
								<span>{getText(scenarioData.title, languageId)}</span>
							</div>
							<div className="AdminGames-cell facilitators"><span>{numberOfFacilitators}</span></div>
							<div className="AdminGames-cell activePlayers"><span>{numberOfPlayers}</span></div>
							<div className="AdminGames-cell gameUrl">
								<span>{appConfig.gameUrl + '/' + game.url}</span>
							</div>
							<div className="AdminGames-cell gameInfo">
								<div className="AdminGames-viewInfoBtn">
									<Button 
										classes={['facilitator']}
										text={getText(adminUiTexts.viewGameInfo, languageId)}
										onClick={() => {handleSelectGame(game.id);}}
									/>
								</div>
							</div>
							<div 
								className="AdminGames-cell gameStats"
								onClick={(e) => {e.stopPropagation(e);}}
							>
								<div className="AdminGames-viewStatsBtn">
									<Button 
										classes={['facilitator']}
										text={getText(adminUiTexts.viewGameStats, languageId)}
										onClick={() => {handleToggleGameStats(game.id);}}
									/>
								</div>

							</div>
						</div>		
					);
				})}
			</div>
		</div>
	);
};

AdminGames.propTypes = {
	languageId: PropTypes.string.isRequired,
	sortProperty: PropTypes.string.isRequired,
	sortDirection: PropTypes.string.isRequired,
	gamesData: PropTypes.array.isRequired,
	playersData: PropTypes.array.isRequired,
	sortGamesByProperty: PropTypes.func.isRequired,
	handleSelectGame: PropTypes.func.isRequired,
	handleToggleGameStats: PropTypes.func.isRequired
};

export default AdminGames;
