import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyDVn3hkZEp2DwZGUm9SrZipk6mCtPM3ZE4',
		authDomain: 'cgl-kelsen-test.firebaseapp.com',
		projectId: 'cgl-kelsen-test',
		storageBucket: 'cgl-kelsen-test.appspot.com',
		messagingSenderId: '1023812091701',
		appId: '1:1023812091701:web:8a751b0785d23902bb167c'
	};	
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyAw8oTEwFyZw81g4ZklXHEFePY4bBZ7BDg',
		authDomain: 'cgl-kelsen-production.firebaseapp.com',
		projectId: 'cgl-kelsen-production',
		storageBucket: 'cgl-kelsen-production.appspot.com',
		messagingSenderId: '946339424690',
		appId: '1:946339424690:web:62c7cc399e7de9f8635cab'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;