const moduleImages = [
	'modules/tasks/multiple-choice/s1-m2-allergies/option-1.svg',
	'modules/tasks/multiple-choice/s1-m2-allergies/option-2.svg',
	'modules/tasks/multiple-choice/s1-m2-allergies/option-3.svg',
	'modules/tasks/multiple-choice/s1-m2-allergies/option-4.svg',
	'modules/tasks/multiple-choice/s1-m2-allergies/option-5.svg',
	'modules/tasks/multiple-choice/s1-m2-eating-cover/option-1.svg',
	'modules/tasks/multiple-choice/s1-m2-eating-cover/option-2.svg',

	'modules/tasks/spot-errors/s1-m2-production-hygiene-issues/background.svg',
	'modules/tasks/spot-errors/s1-m2-production-issues/errors.svg'
];

export {
	moduleImages
};