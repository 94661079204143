import React from 'react';
import PropTypes from 'prop-types';
import ReactExport from 'react-export-excel';
import {adminUiTexts} from 'data/ui-texts/admin-ui-texts';
import {scenariosData} from 'data/scenarios-data';
import {getStatsSheets} from 'helpers/stats-helper';
import {
	getPlayerModuleData, 
	checkIfModuleGroupIsCompleted,
	getNumberOfAttemptsAndErrors,
} from 'helpers/module-helper';
import {getTimeSpentInModule} from 'helpers/time-helper';
import {getText} from 'helpers/language-helper';
import dayjs from 'dayjs';
import './admin-download-stats.scss';

const AdminDownloadStats = ({languageId, games, players}) => {

	/* Prepare sheets */
	let statsSheets = getStatsSheets(languageId);

	/* Get game stats */
	games.forEach((game) => {
		const created = (game && game.created ? dayjs(game.created).format('DD/MM-YY') : '-');
		const scenarioId = (game && game.scenarioId ? game.scenarioId : 'scenario-1');
		const scenarioData = scenariosData.find((sc) => {return sc.id === scenarioId;});
		const numberOfFacilitators = (game.facilitatorEmails ? game.facilitatorEmails.length : 0);
		const numberOfPlayers = players.filter((player) => {
			return (player.gameId === game.id && player.isInactive !== true);
		}).length;
		const gameStatsObj = {
			gameName: game.name,
			created: created,
			scenario: getText(scenarioData.title, languageId),
			facilitators: numberOfFacilitators,
			players: numberOfPlayers,
			url: game.url
		};
		const sheetIndex = statsSheets.findIndex((sheet) => {return sheet.id === 'games';});
		if (sheetIndex >= 0) statsSheets[sheetIndex].data.push(gameStatsObj);
	});

	/* Get general stats */
	scenariosData.forEach((scenarioData) => {
		scenarioData.moduleGroups.forEach((moduleGroup) => {
			const playersInModuleGroup = players.filter((p) => {
				return (
					p.isInactive !== true && 
					p.modules &&
					p.modules.some((m) => {
						return (
							moduleGroup.moduleIds.indexOf(m.moduleId) >= 0 &&
							m.sessions &&
							m.sessions.length > 0
						);
					})
				);
			});

			let numberOfAttempts = 0;
			let numberOfErrors = 0;
			let timePlayed = 0;
			moduleGroup.moduleIds.forEach((moduleId) => {
				playersInModuleGroup.forEach((playerData) => {
					const playerModuleData = getPlayerModuleData(moduleId, playerData);
					if (playerModuleData) {
						const {attempts, errors} = getNumberOfAttemptsAndErrors(playerModuleData);
						numberOfAttempts += attempts;
						numberOfErrors += errors;
						timePlayed += getTimeSpentInModule(playerModuleData);
					}
				});
			});
			let numberOfPlayersCompletedModuleGroup = 0;
			playersInModuleGroup.forEach((playerData) => {
				if (checkIfModuleGroupIsCompleted(moduleGroup.moduleIds, playerData)) {
					numberOfPlayersCompletedModuleGroup += 1;
				}
			});

			let avgTimePlayed = 0;
			if (playersInModuleGroup.length > 0) {
				avgTimePlayed = (timePlayed / playersInModuleGroup.length).toFixed(1);
			}
			let avgErrors = 0;
			if (numberOfAttempts > 0) {
				avgErrors = (numberOfErrors / numberOfAttempts).toFixed(2);
			}

			const generalStatsObj = {
				moduleGroup: getText(moduleGroup.title, languageId),
				players: playersInModuleGroup.length,
				timeSpent: avgTimePlayed,
				errors: avgErrors,
				completed: numberOfPlayersCompletedModuleGroup
			};
			const sheetIndex = statsSheets.findIndex((sheet) => {return sheet.id === 'general';});
			if (sheetIndex >= 0) statsSheets[sheetIndex].data.push(generalStatsObj);
		});
	});
	

	/* Get module stats */
	scenariosData.forEach((scenarioData) => {
		scenarioData.modulesData.forEach((moduleData) => {
			moduleData.tasks.forEach((taskData) => {
				if (taskData.isSolveToContinue === true) {
					let numberOfErrors = 0;
					let numberOfPerfectAttempts = 0;
					let timesPlayed = 0;
					players.forEach(((player) => {
						if (!player.isInactive) {
							const playerModuleData = getPlayerModuleData(moduleData.id, player);
							if (
								playerModuleData && 
								playerModuleData.sessions &&
								playerModuleData.sessions.length > 0
							) {
								playerModuleData.sessions.forEach((session) => {
									if (session.tasks) {
										const playerTaskData = session.tasks.find((t) => {
											return t.taskId === taskData.taskId;
										});
										if (playerTaskData && playerTaskData.isCompleted === true) {
											timesPlayed += 1;
											if (playerTaskData.hasOwnProperty('errors')) {
												numberOfErrors += playerTaskData.errors;
												if (playerTaskData.errors === 0) {
													numberOfPerfectAttempts += 1;
												}
											} else {
												numberOfPerfectAttempts += 1;
											}
										}
									}
								});
							}
						}
					}));
					const avgErrors = (timesPlayed > 0 
						? (numberOfErrors / timesPlayed).toFixed(1)
						: '-'
					);
					const percentPerfectAttempts = (timesPlayed > 0
						? ((numberOfPerfectAttempts / timesPlayed) * 100).toFixed(1)
						: '-'
					);
					const moduleStatsObj = {
						task: taskData.id,
						type: taskData.type,
						mistakes: avgErrors,
						perfectAttempts: percentPerfectAttempts,
						attempts: timesPlayed
					};
					const sheetIndex = statsSheets.findIndex((sheet) => {return sheet.id === moduleData.id;});
					if (sheetIndex >= 0) statsSheets[sheetIndex].data.push(moduleStatsObj);
				}
			});
		});
	});

	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

	return (
		<div className="AdminDownloadStats">
			<ExcelFile 
				element={
					<button className="AdminDownloadStats-btn">
						{getText(adminUiTexts.gameStatsDownload.downloadStats, languageId)}
					</button>
				}
			>
				{statsSheets.map((sheet, index) => {
					return (
						<ExcelSheet key={index} data={sheet.data} name={sheet.name}>
							{sheet.columns.map((column, cIndex) => {
								return <ExcelColumn key={cIndex} label={column.label} value={column.value} />;
							})}
						</ExcelSheet>
					);
				})}
			</ExcelFile>
		</div>
	);
};

AdminDownloadStats.propTypes = {
	languageId: PropTypes.string.isRequired,
	games: PropTypes.array.isRequired,
	players: PropTypes.array.isRequired,
};

export default AdminDownloadStats;