import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import {getGameUrl} from 'helpers/game-helper';
import {scenariosData} from 'data/scenarios-data';
import {backgroundsData} from 'data/backgrounds-data';
import Login from './login';
import FacilitatorController from 'components/users/facilitator-controller';
import PlayerController from 'components/users/player-controller';
import ImageLoader from 'components/ui/image-loader/image-loader';
import Background from 'components/ui/background/background';
import './login.scss';

class LoginController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoggedIn: false,
			playerLoginType: null,
			gameData: null,
			userId: null,
			isFacilitator: false,
			email: null,
			backgroundId: 'outside',
			backgroundModifier: 'top',
			backgroundStatus: 'show',
			shakeScreen: false,
		};
		this.timeout = null;
		this.unsubscribeOnAuthStateChanged = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		/* Check if logged in */
		this.checkIfLoggedIn();

		/* Check if valid game url */
		const gameUrl = getGameUrl(window.location.pathname);
	
		if (!gameUrl || gameUrl.length === 0 || gameUrl === '/') {
			/* Site root */
			this.setState({gameData: null});
		} else {
			/* Get game data from url */
			const db = firebase.firestore();
			db.collection(appConfig.gamesDbName).get().then((querySnapshot) => {
				let gameData = null;
				querySnapshot.forEach((doc) => {
					if (gameData) return;
					if (doc.data() && doc.data().url === gameUrl.toLowerCase()) {
						gameData = {id: doc.id, ...doc.data()};
					}
				});
				if (gameData && gameData.scenarioId) {
					/* Check if login is required */
					const scenarioData = scenariosData.find((s) => {return s.id === gameData.scenarioId;});
					if (scenarioData) {
						this.setState({gameData: gameData, playerLoginType: scenarioData.playerLoginType});
					} else {
						/* No scenario data */
						this.setState({gameData: null});
					}
				} else {
					/* No game at this url */
					this.setState({gameData: null});
				}
			});
		}
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeOnAuthStateChanged !== null) this.unsubscribeOnAuthStateChanged();
	};

	/**
	 * Check if user is logged in
	 */
	checkIfLoggedIn = () => {
		// Unsubscribe previous onAuthStateChanged
		if (this.unsubscribeOnAuthStateChanged !== null) {
			this.unsubscribeOnAuthStateChanged();
		}

		// Subscribe to onAuthStateChanged
		this.unsubscribeOnAuthStateChanged = firebase.auth().onAuthStateChanged((user)=>{
			if (user) {
				const isFacilitator = user.email !== null;
				if (isFacilitator) {
					/* Logged in as facilitator */
					this.setState({
						isLoggedIn: true,
						isLoading: false,
						userId: user.uid,
						isFacilitator: isFacilitator,
						email: user.email
					});
				} else {
					/* Logged in as player */
					this.setState({
						isLoggedIn: true, 
						isLoading: false, 
						userId: user.uid,
						backgroundModifier: 'bottom',
						backgroundStatus: 'pan'
					});
				}
			} else {
				this.setState({
					isLoading: false,
					isLoggedIn: false,
					userId: null,
					isFacilitator: false,
					email: null,
					backgroundModifier: 'top'
				});
			}
		});	
	};

	/**
	 * Logout
	 */
	handleLogout = () => {		
		firebase.auth().signOut();
		this.setState({
			isLoggedIn: false,
			userId: null,
			isFacilitator: false,
			email: null,
		});
	};

	/**
	 * Switch between facilitator / player login box
	 * @param {string} activeLoginBox 
	 */
	setActiveLoginBox = (activeLoginBox) => {
		this.setState({activeLoginBox});
	};


	/**
	 * Switch background
	 * @param {string} backgroundId 
	 * @param {string} backgroundModifier 
	 */
	setBackground = (backgroundId, backgroundModifier = null) => {
		if (
			backgroundId !== this.state.backgroundId || 
			backgroundModifier !== this.state.backgroundModifier
		) {
			
			/* Get background data */
			const backgroundData = backgroundsData.find((b) => {return b.id === backgroundId;});

			if (backgroundData) {
				/* Check position id */
				let newBackgroundModifier = backgroundModifier;
				if (
					backgroundData.modifiers.length > 0 &&
					(
						!backgroundModifier || 
						!backgroundData.modifiers.some((p) => {return p.id === newBackgroundModifier;})
					)
				) {
					/* Position required, but no position id or invalid position id provided */
					newBackgroundModifier = backgroundData.modifiers[0].id;
				}

				if (
					backgroundId !== this.state.backgroundId ||
					newBackgroundModifier !== this.state.backgroundModifier
				) {
					let backgroundStatus = (backgroundId === this.state.backgroundId ? 'pan' : 'fade');
					if (
						(this.state.backgroundModifier && this.state.backgroundModifier.includes('zoom')) || 
						(newBackgroundModifier && newBackgroundModifier.includes('zoom'))
					) {
						backgroundStatus = 'zoom';
					}
					this.setState({
						backgroundId: backgroundId,
						backgroundModifier: newBackgroundModifier,
						backgroundStatus: backgroundStatus
					});
				}
			}
		}
	};

	/**
	 * Update background status (show, pan, fade, zoom)
	 * @param {string} status 
	 */
	updateBackgroundStatus = (status) => {
		this.setState({backgroundStatus: status});
	};

	/**
	 * Shake background
	 */
	handleShakeScreen = () => {
		this.setState({shakeScreen:true}, () => {
			if (this.timeout) clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {this.setState({shakeScreen: false});}, 500);
		});
	};

	/**
 	* Render component
 	*/
	render() {
		/* User is logged in, get controller component for facilitator / player */
		const UserComponent = (this.state.isFacilitator ? FacilitatorController : PlayerController);
		
		/* Get scenario id */
		const gameData = this.state.gameData;
		const scenarioId = (gameData && gameData.scenarioId ? gameData.scenarioId : 'scenario-1');

		return (
			<div className={'LoginController' + (this.state.shakeScreen ? ' shake' : '')}>
				<Background
					scenarioId={scenarioId} 
					backgroundId={this.state.isFacilitator ? 'facilitator' : this.state.backgroundId}
					backgroundModifier={this.state.backgroundModifier}
					backgroundStatus={this.state.backgroundStatus}
					updateBackgroundStatus={this.updateBackgroundStatus}
				/>
				{!this.state.isLoggedIn ?
					<Login 
						languageId={this.props.languageId}
						playerLoginType={this.state.playerLoginType}
						gameData={this.state.gameData}
						activeLoginBox={this.state.activeLoginBox}
						setActiveLoginBox={this.setActiveLoginBox}
						startWithoutLogin={this.startWithoutLogin}
					/>
					:
					<>
						<ImageLoader type={this.state.isFacilitator ? 'facilitator' : 'basic-game'} />
						<UserComponent 
							languageId={this.props.languageId}
							backgroundStatus={this.state.backgroundStatus}
							gameData={this.state.gameData}
							userId={this.state.userId}
							scrollToTop={this.props.scrollToTop}
							handleLogout={this.handleLogout}
							setBackground={this.setBackground}
							handleShakeScreen={this.handleShakeScreen}
						/>
					</>
				}
			</div>
		);
	}
}

LoginController.propTypes = {
	languageId: PropTypes.string.isRequired,
	scrollToTop: PropTypes.func.isRequired,
};

export default LoginController;