// NOTE: remember to update the UI-texts file on the drive, if you update this file */
const loginUiTexts = {
	loginManagerText: {
		da: 'Velkommen til første modul af hygiejnetræningen. Du skal igennem to korte moduler. Jeg er din leder og hjælper dig gennem spillet.',
	},
	start: {
		da: 'Start',
		en: 'Start',
		pl: 'Start',
		de: 'Start'
	},
	login: {
		da: 'Log ind',
		en: 'Log in',
		pl: 'Zaloguj się',
		de: 'Anmeldung'
	},
	logout: {
		da: 'Log ud',
		en: 'Log out',
		pl: 'Wyloguj się',
		de: 'Ausloggen'
	},
	player: {
		da: 'Spiller',
		en: 'Player',
		pl: 'Gracz',
		de: 'Spieler'
	},
	facilitator: {
		da: 'Facilitator',
		en: 'Facilitator',
		pl: 'Moderator',
		de: 'Moderator'
	},
	admin: {
		da: 'Administrator',
		en: 'Administrator',
		pl: 'Administrator',
		de: 'Administrator'
	},
	email: {
		da: 'e-mail',
		en: 'e-mail',
		pl: 'e-mail',
		de: 'Email'
	},
	password: {
		da: 'password',
		en: 'password',
		pl: 'hasło',
		de: 'Passwort'
	},
	passwordRepeat: {
		da: 'gentag password',
		en: 'repeat password',
		pl: 'powtórz hasło',
		de: 'Passwort wiederholen'
	},
	userId: {
		da: 'medarbejder-nr.',
		en: 'employee no.',
		pl: 'numer pracownika',
		de: 'Mitarbeiter-Nr.'
	},
	name: {
		da: 'navn',
		en: 'name'
	},
	loginWithUserId: {
		da: 'Log ind med medarbejder-nr.',
		en: 'Log in with employee no.',
		pl: 'Zaloguj się za pomocą numeru pracownika',
		de: 'Melden Sie sich mit der Mitarbeiter-Nr. an.'
	},
	createNewUser: {
		da: 'Opret ny bruger',
		en: 'Create new user',
		pl: 'Utwórz nowego użytkownika',
		de: 'Neuen Benutzer erstellen'
	},
	forgotPassword: {
		da: 'Glemt dit password?',
		en: 'Forgot your password?',
		pl: 'Zapomniałeś hasła?',
		de: 'Haben Sie Ihr Passwort vergessen?'
	},
	resetPassword: {
		da: 'Nulstil password',
		en: 'Reset tpassword',
		pl: 'Zresetuj hasło',
		de: 'Passwort zurücksetzen'
	},
	goToLoginBtn: {
		da: 'Tilbage til login',
		en: 'Back to login',
		pl: 'Powrót do logowania',
		de: 'Zurück zur Anmeldung'
	},
	createUserBtn: {
		da: 'Opret',
		en: 'Create',
		pl: 'Utwórz',
		de: 'Erstellen'
	},
	reset: {
		da: 'Nulstil',
		en: 'Reset',
		pl: 'Zresetuj',
		de: 'Zurücksetzen'
	},
	cancel: {
		da: 'Afbryd',
		en: 'Cancel',
		pl: 'Anuluj',
		de: 'Stornieren'
	},
	cancel2: {
		da: 'Annuller',
		en: 'Cancel',
		pl: 'Anuluj',
		de: 'Stornieren'
	},
	createUserFeedback: {
		da: 'Din bruger er blevet oprettet. Gå tilbage og log ind for at spille.',
		en: 'Your new user has been created. Go back to log in to play.',
		pl: 'Twój użytkownik został utworzony. Wróć i zaloguj się, aby grać.',
		de: 'Ihr neuer Benutzer wurde erstellt. Gehen Sie zurück, um sich zum Spielen anzumelden.'
	},
	passwordResetFeedback: {
		da: 'Du har modtaget et link til at nulstille dit password i din indbakke.',
		en: 'You have received a link to reset your password in your inbox.',
		pl: 'Otrzymałeś link do zresetowania hasła w swojej skrzynce odbiorczej.',
		de: 'Sie haben in Ihrem Posteingang einen Link zum Zurücksetzen Ihres Passworts erhalten.'
	}
};




module.exports = {
	loginUiTexts
};