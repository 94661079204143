import React from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import {loginUiTexts} from 'data/ui-texts/login-ui-texts';
import {playerUiTexts} from 'data/ui-texts/player-ui-texts';
import {scenariosData} from 'data/scenarios-data';
import {languagesData} from 'data/languages-data';
import Button from 'components/ui/button/button';
import Logo from 'components/ui/logo/logo';
import './choose-language.scss';

const ChooseLanguage = ({languageId, handleChooseLanguage, handleLogout, scenarioId}) => {

	let availableLanguages = languagesData;

	if (scenarioId) { 
		const scenarioData = scenariosData.find((scenario) => {
			return scenario.id === scenarioId;
		});
		if (scenarioData && scenarioData.languageIds) {
			availableLanguages = languagesData.filter((language) => {
				return scenarioData.languageIds.indexOf(language.id) >= 0;
			});
		}
	}

	return (
		<div className="ChooseLanguage">
			<div className="ChooseLanguage-logoCGL">
				<Logo type="cgl"/>
			</div>
			<div className="ChooseLanguage-content">
				<div className="ChooseLanguage-title">
					<span>{getText(playerUiTexts.chooseLanguage, 'da')}</span>
				</div>
				<div className="ChooseLanguage-subtitle">
					<span>{getText(playerUiTexts.chooseLanguage, 'en')}</span>
				</div>
				<div className="ChooseLanguage-languages">
					{availableLanguages.map((language) => {
						return (
							<div 
								key={language.id}
								className={'ChooseLanguage-language ' + language.id}
								onClick={() => {handleChooseLanguage(language.id);}}
							/>
						);
					})}
				</div>
			</div>

			{/* Logout btn */}
			<div className="ChooseLanguage-logoutBtn" > 
				<Button
					text={getText(loginUiTexts.logout, languageId)}
					classes={['logout-player']}
					onClick={() => {handleLogout();}}
				/>
			</div>
		</div>
	);
};

ChooseLanguage.propTypes = {
	languageId: PropTypes.string.isRequired,
	scenarioId: PropTypes.string,
	handleChooseLanguage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	
};

export default ChooseLanguage;
